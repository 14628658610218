import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { Container, Accordion, Card } from "react-bootstrap";
import { RiArrowDropDownLine } from "react-icons/ri";
import Nabvar from "./Navbar";
import Footer from "./footer";
import Plans from "./Plans";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { RxCrossCircled } from "react-icons/rx";

const Pricing = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [accessData, setAccessData] = useState([]);

  const getAccessData = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetSubscriptionPlansModule`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        setAccessData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching access data:", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getAccessData();
    const script = document.createElement("script");
    script.innerHTML = `
      document.addEventListener("DOMContentLoaded", function() {
          console.log("Running SEO script...");

          const seoUrl = "https://seobix.com/integrations/meta_seo.php";
           const requestBody = JSON.stringify({
              weburl: "https://billpunch.com",
              fileurl: "https://billpunch.com/SeoSettings_638749552913980001.enc",
              type:"Seo"
          });

          fetch(seoUrl, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: requestBody
          })
          .then(response => response.json())
          .then(seoData => {
              console.log("SEO Data:", seoData);

              if (seoData.status !== "1" || !seoData.data || seoData.data.length === 0) {
                  console.error("Invalid SEO response format");
                  return;
              }

             const currentUrl = window.location.href.replace(/\/$/, '').replace(/^https?:\/\/www\./, 'https://');
            console.log(currentUrl);
            const page = seoData.data[0].pages.find(p => p.page_url === currentUrl);
            if (!page) {
                console.error(currentUrl+"Page not found");
                return;
            }

              const { basic_settings, og_settings, keyword_settings } = page;

              if (basic_settings.tittle) document.title = basic_settings.tittle;

              const metaTags = {
                  "description": basic_settings.meta_desc,
                  "keywords": keyword_settings.map(k => k.keyword).join(", "),
                  "og:title": og_settings.og_title,
                  "og:description": og_settings.og_desc,
                  "og:image": og_settings.og_image,
                  "og:url": og_settings.og_url,
                  "og:type": og_settings.og_type,
                  "og:site_name": og_settings.og_sitename,
                  "twitter:title": og_settings.twitter_title,
                  "twitter:description": og_settings.twitter_desc,
                  "twitter:image": og_settings.twitter_image
              };

              Object.entries(metaTags).forEach(([key, value]) => {
                  if (value) {
                      let metaTag = document.querySelector(\`meta[name="\${key}"], meta[property="\${key}"]\`);
                      if (!metaTag) {
                          metaTag = document.createElement("meta");
                          if (key.startsWith("og:") || key.startsWith("twitter:")) {
                              metaTag.setAttribute("property", key);
                          } else {
                              metaTag.setAttribute("name", key);
                          }
                          document.head.appendChild(metaTag);
                      }
                      metaTag.setAttribute("content", value);
                  }
              });
          })
          .catch(error => console.error("SEO Script Error:", error));
      });
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
    
      <Nabvar />
      <div>
        <Plans />
        <div className="mt-md-4">
          <h5 className="fancy">Distribution of Plans</h5>
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col-9">
            <Container className="m-0 p-0">
              <Accordion>
                {accessData.map((module, index) => (
                  <Card className="m-0 p-2 corners corners--repeated-circles" key={index}>
                    <Card.Header
                      as={Accordion.Toggle}
                      eventKey={index.toString()}
                      className="m-0 p-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: ".2rem",
                        border: "1px solid #7b628f",
                        background: "#E5D4FF",
                      }}
                    >
                      <div style={{ color: "#3f1660" }}>{module.module}</div>
                      <div>
                        <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
                      </div>
                    </Card.Header>

                    <Accordion.Collapse eventKey={index.toString()}>
                      <Card.Body
                        className="mt-2"
                        style={{
                          background: "#FEFAF6",
                          border: "1px solid rgb(170, 215, 189)",
                        }}
                      >
                        <div className="row">
                          <h6 className="col"></h6>
                          <table>
                            <thead>
                              <tr>
                                <th className="text-center" style={{ background: "#FEFAF6", color: "#FDDE55" }}></th>
                                <th
                                  className="text-center"
                                  style={{
                                    background: "#102C57",
                                    color: "#FDDE55",
                                    borderTopLeftRadius: "1rem",
                                    borderBottomLeftRadius: "1rem",
                                  }}
                                >
                                  Basic
                                </th>
                                <th className="text-center" style={{ background: "#102C57", color: "#FDDE55" }}>
                                  Premium
                                </th>
                                <th
                                  className="text-center"
                                  style={{
                                    background: "#102C57",
                                    color: "#FDDE55",
                                    borderTopRightRadius: "1rem",
                                    borderBottomRightRadius: "1rem",
                                  }}
                                >
                                  Enterprise
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {module.submodule.map((submodule, subIndex) => (
                                <tr className="col-4 text-center" key={subIndex}>
                                  <td className="m-0 p-0" style={{ border: "none", fontSize: "1rem" }}>
                                    {submodule.submodule_name}
                                  </td>
                                  <td className="text-center" style={{ border: "none" }}>
                                    {submodule.basic === "Y" ? (
                                      <IoMdCheckmarkCircleOutline style={{ color: "green", fontSize: "1.5rem" }} />
                                    ) : (
                                      <RxCrossCircled style={{ color: "red", fontSize: "1.5rem" }} />
                                    )}
                                  </td>
                                  <td className="text-center" style={{ border: "none" }}>
                                    {submodule.premium === "Y" ? (
                                      <IoMdCheckmarkCircleOutline style={{ color: "green", fontSize: "1.5rem" }} />
                                    ) : (
                                      <RxCrossCircled style={{ color: "red", fontSize: "1.5rem" }} />
                                    )}
                                  </td>
                                  <td className="text-center" style={{ border: "none" }}>
                                    {submodule.enterprise === "Y" ? (
                                      <IoMdCheckmarkCircleOutline style={{ color: "green", fontSize: "1.5rem" }} />
                                    ) : (
                                      <RxCrossCircled style={{ color: "red", fontSize: "1.5rem" }} />
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Container>
          </div>
          <div className="col"></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
